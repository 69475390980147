import "./index.css";

import { enableIEpolyfills } from "../helpers/polyfill";

enableIEpolyfills();

document.addEventListener("DOMContentLoaded", (event) => {

  /* START JS for removing payment holding page (page in between donate form and payment) */
  let htmlElement = document.documentElement;
	htmlElement.classList.add('JS');
	htmlElement.classList.remove('no-JS');

  /** There is a page after filling single donate form, which we want to hide from users so that it goes
   * straight to opayo/elavon payment page rather than user having to press submit again, so below will submit on domLoad
   * as long as js is on client side otherwise this page will show with js off and user will have to press submit to pay" */
  const paymentHiddenSubmitButton = document.querySelector(".selectSubmit");
  if (paymentHiddenSubmitButton) {
    paymentHiddenSubmitButton.submit();
  }
  /* END JS for removing payment holding page (page in between donate form and payment) */


});

window.addEventListener("load", (event) => {
  /* START JS for showing phone field only when phone or sms consent checkboxes are selected */
  const phoneField = document.querySelector(".phoneField");
  const phoneFieldError = document.querySelector('[for="donation_phone_number"] + .Error');
  const phoneConsentField = document.querySelector("#donation_phone_consent");
  const smsConsentField = document.querySelector("#donation_sms_consent");
  const phoneArray = [phoneConsentField, smsConsentField]

  phoneArray.forEach((item) => {
    if (item) {
      item.addEventListener("change", (event) => {
        hideDisplayPhone();
      });
    }
  });

  //Run this function incase form errors
  hideDisplayPhone();

  /* hide phone number field unless phone or sms consent selected */
  function hideDisplayPhone() {
    if (phoneConsentField ||  smsConsentField) {
      if (phoneConsentField.checked ||  smsConsentField.checked ) {
        phoneField.style="display:flex";
        phoneField.classList.remove("phoneField--hide");
        phoneField.classList.add("phoneField--show");
        if (phoneFieldError) {
          phoneFieldError.style="display:block";
        }
      } else {
        phoneField.style="display:none";
        phoneField.classList.remove("phoneField--show");
        phoneField.classList.add("phoneField--hide");
        if (phoneFieldError) {
          phoneFieldError.style="display:none";
        }
      }
    } else {
      return
    }
  }
  /* END JS for showing phone field only when phone or sms consent checkboxes are selected */

    if (document.querySelector('.donationForm')) {
      FormValidation(document.querySelector('.donationForm'), {
        selector: '.formSection.active .form__control',
        submitMessage: '.js-form-alert',
        messageInvalidEmail: 'That looks like an invalid email address',
        messageInvalidPostcode: 'That looks like an invalid postcode',
        messageInvalidPassword: 'That looks like an invalid email address',
        messageSummaryMultiple: 'Some form fields need correcting, please review the form.',
        messageSummarySingle: 'A form field needs correcting, please review the form.'
      }) 
    } else {
      return;
    }
});
