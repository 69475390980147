import "./index.css";

class DonateBlock {
  constructor(node) {
    this.node = node;

    this.periods = node.querySelectorAll(".js-donate-block__period--input");
    this.selectedPeriod = node.querySelector(
      ".js-donate-block__period--input:checked"
    );

    this.defaultFrequency = node.getAttribute("data-default-frequency");
    this.defaultAmountGroup = node.querySelector(
      `.js-donate-block__amounts[data-amount-collection="${this.defaultFrequency}"]`
    );
    this.defaultAmount = node.querySelector(
      `.donate-block__amounts--${this.defaultFrequency} input:checked`
    )
      ? node.querySelector(
          `.donate-block__amounts--${this.defaultFrequency} input:checked`
        )
      : node.querySelector(".js-donate-block__currency-input-field");

    this.amounts = node.querySelectorAll(".js-donate-block__choice--input");
    this.amountGroups = node.querySelectorAll(".js-donate-block__amounts");
    this.userInput = node.querySelector(
      ".js-donate-block__currency-input-field"
    );

    this.init = this.init.bind(this);
    this.initPeriodMatch = this.initPeriodMatch.bind(this);
    this.periodMatch = this.periodMatch.bind(this);
    this.togglePeriod = this.togglePeriod.bind(this);
    this.toggleAmount = this.toggleAmount.bind(this);
    this.updateUserInput = this.updateUserInput.bind(this);
    this.updateNudgeText = this.updateNudgeText.bind(this);
    this.updateNudgeImage = this.updateNudgeImage.bind(this);
    this.updateDonateButton = this.updateDonateButton.bind(this);

    this.init(
      this.defaultAmount,
      this.defaultFrequency,
      this.defaultAmountGroup,
      this.selectedPeriod
    );

    this.periods.forEach(period => {
      period.addEventListener("click", this.togglePeriod);
    });

    this.amounts.forEach(amount => {
      amount.addEventListener("click", this.toggleAmount);
    });

    this.userInput.addEventListener("input", this.updateUserInput);
  }

  init(amount, frequency, defaultAmountGroup, currentPeriod) {
    if (amount.classList.contains("js-donate-block__currency-input-field")) {
      amount.setAttribute("data-donation-type", frequency);
    }

    if (
      this.defaultAmountGroup.getAttribute("data-amount-collection") !==
      currentPeriod.value
    ) {
      this.initPeriodMatch(currentPeriod);
    } else {
      this.updateNudgeText(amount);
      this.updateDonateButton(amount);
    }
  }

  togglePeriod(e) {
    this.amountGroups.forEach(amountGroup => {
      this.periodMatch(amountGroup, e.target);
    });
  }

  initPeriodMatch(currentPeriod) {
    this.amountGroups.forEach(amountGroup => {
      this.periodMatch(amountGroup, currentPeriod);
    });
  }

  periodMatch(period, currentPeriod) {
    if (period.getAttribute("data-amount-collection") === currentPeriod.value) {
      period.classList.add("donate-block__amounts--visible");
      this.userInput.setAttribute("data-donation-type", currentPeriod.value);
      this.updateNudgeText(
        period.querySelector(".js-donate-block__choice--input:checked")
          ? period.querySelector(".js-donate-block__choice--input:checked")
          : this.userInput
      );
      this.updateNudgeImage(
        period.querySelector(".js-donate-block__choice--input:checked")
          ? period.querySelector(".js-donate-block__choice--input:checked")
          : this.userInput
      );
      this.updateDonateButton(
        period.querySelector(".js-donate-block__choice--input:checked")
          ? period.querySelector(".js-donate-block__choice--input:checked")
          : this.userInput
      );
    } else {
      period.classList.remove("donate-block__amounts--visible");
    }
  }

  toggleAmount(e) {
    this.updateNudgeText(e.target);
    this.updateNudgeImage(e.target);
    this.updateDonateButton(e.target);
    this.userInput.value = "";
  }

  updateUserInput(e) {
    this.updateNudgeText(e.target);
    this.updateNudgeImage(e.target);
    this.updateDonateButton(e.target);
    this.amountGroups.forEach(amountGroup => {
      if (
        amountGroup.querySelector(".js-donate-block__choice--input:checked") !==
        null
      ) {
        // eslint-disable-next-line no-param-reassign
        amountGroup.querySelector(
          ".js-donate-block__choice--input:checked"
        ).checked = false;
      }
    });
  }

  updateNudgeText(choice) {
    this.node.querySelector(
      ".js-donate-block__nudgeText"
    ).innerText = choice.getAttribute("data-nudge-text");
  }

  updateNudgeImage(choice) {
    this.node.querySelector(
      ".js-donate-block__nudgeImage"
    ).src = choice.getAttribute("data-nudge-image");
  }

  updateDonateButton(choice) {
    this.node.querySelector(".js-donate-block__submit span").innerText = `£${
      choice.value
    } ${choice.getAttribute("data-donation-type")}`;
  }
}

document.querySelectorAll(".js-donate-block").forEach(node => {
  new DonateBlock(node);
});

/* Add prefetch link element in head when user hovers or touches the donate amount buttons
to help load images faster. */

const prefetchElement = document.createElement("link");
const isSupported =
  prefetchElement.relList &&
  prefetchElement.relList.supports &&
  prefetchElement.relList.supports("prefetch") &&
  window.IntersectionObserver &&
  "isIntersecting" in IntersectionObserverEntry.prototype;

function prefetchImage() {
  const amountButtonLabel = this.closest("label");
  const amountButtonInput = amountButtonLabel.querySelector(
    ".js-donate-block__choice--input"
  );

  if (amountButtonInput && !amountButtonInput.hasAttribute("imagePrefetched")) {
    const inputAttribute = amountButtonInput.getAttribute("data-nudge-image");
    const linkElement = document.createElement("link");
    linkElement.rel = "prefetch";
    linkElement.href = inputAttribute;
    linkElement.as = "image";
    document.head.appendChild(linkElement);
    amountButtonInput.setAttribute("imagePrefetched", "");
  }
}

if (isSupported) {
  const eventListenersOptions = {
    capture: true,
    passive: true
  };

  const amountButtons = document.querySelectorAll(".Trigger");
  amountButtons.forEach(amountButton => {
    amountButton.addEventListener(
      "mouseover",
      prefetchImage,
      eventListenersOptions
    );
    amountButton.addEventListener(
      "touchstart",
      prefetchImage,
      eventListenersOptions
    );
  });
}
