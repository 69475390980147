import "./index.css";

const selectBoxesRelations = document.querySelectorAll(".SelectBoxRelations");

if (selectBoxesRelations) {
  selectBoxesRelations.forEach((selectBoxRelation) => {
    const selection = selectBoxRelation.querySelector(".Select");
    const selectionOther = selectBoxRelation.querySelector(
      ".Giftaid-relation--other"
    );

    selection.addEventListener("change", function () {
      if (this.value === "Other") {
        selectionOther.classList.remove("Giftaid--hide");
      } else {
        selectionOther.classList.add("Giftaid--hide");
      }
    });
  });
}
