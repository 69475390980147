window.FormValidation = function(formElement, options) {

  // If the element doesn't exist or the browser isn't good enough, adios.
  if(!formElement || !('querySelector' in document && 'addEventListener' in window && 'localStorage' in window && 'classList' in document.createElement('a'))) {
    return;
  }

  /* On submitting form, Rails checks for any errors and reloads form if there are errors. 
   * We need to open up all the steps so users are able to see the whole form if frontend validation
   * doesn't pick something up */ 
  const railsErrors = document.querySelector('.ErrorSummary');
    if (railsErrors) {
      var formSteps = document.querySelectorAll(".formSection");
      formSteps.forEach(step => {
       step.style.display = "block"
      });
       document.getElementById("nextBtn").style.display = "none";
       document.getElementById("prevBtn").style.display = "none";
       document.getElementById("Submit").style.display = "inline-block";
       return
    } 

  this.options = options || {};

  var form = formElement;

  let ul = document.getElementById("errorsList");

  var displayError = function(el, liveRegion, message) {
    // Make the form field accessibly invalid
    if (window.getComputedStyle(el).display === "none") {
      return
    } else {
      el.setAttribute('aria-invalid', 'true');
      el.setAttribute('aria-describedby', liveRegion.id);
    }

    // Insert the error message
    liveRegion.textContent = message;
  };

  function capitalizeFirstLetter(string) {
    return string.replace(/^./, string[0].toUpperCase())
  }

  // Get a succeeding sibling
  var getFollowingSibling = function(el, sibling) {
    if(el.nextSibling.nodeName.toLowerCase() === sibling) {
      return el.nextSibling;
    } else {
      return getFollowingSibling(el.nextSibling, sibling);
    }
  };

  var controls = form.querySelectorAll(options.selector);

  for(var i = 0, ii = controls.length; i < ii; i++) {
    controls[i].addEventListener('blur', function(e) {

      // The blurred form control
      var blurred = e.target;

      // Get the aria-live region
      var liveRegion = getFollowingSibling(blurred, 'span');

      // Use appropriate error messages
      if(blurred.parentNode.querySelector(':invalid')) {
        runValidation(blurred);
      }
    });
  }

  /* Page steps here, this bit was picked up from w3schools example */

  var currentStep = 0; // Current tab is set to be the first tab (0)
  showStep(currentStep); // Display the current tab

  function showStep(n) {
    // This function will display the specified tab of the form...
    let x = document.getElementsByClassName("formSection");
    let stepTracker = document.getElementById("stepTracker");

    x[n].style.display = "block";
    
    /* Scroll page upto steptracker position for next and previosu steps */
    if (n > 0) {
      stepTracker.scrollIntoView(true, { behavior: "instant" });
    } else {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }

    let nextBtnText;

    /* if donation type is hidden and only gift aid shown because of specific utm params then update next button text to giftaid */
    let giftAidStepNext = document.querySelector('.js-Giftaid.hideDonationType');

    if (giftAidStepNext && n == 0) {
      nextBtnText = "Next: Gift Aid"
    } else {
      nextBtnText = "Next: donation type"
    }

    // Text on next Button
    switch (n) {
      case 0:
        nextBtnText;
        break;
      case 1:
        nextBtnText = "Next: keep in touch";
        break;
      default:
        nextBtnText = "Next";
    }
    
    if (n == 0) {
      document.getElementById("prevBtn").style.display = "none";
    } else {
      document.getElementById("prevBtn").style.display = "inline";
    } 
    if (n == (x.length - 1)) {
      document.getElementById("nextBtn").style.display = "none";
      document.getElementById("Submit").style.display = "block";
    } else {
      document.getElementById("nextBtn").style.display = "inline";
      document.getElementById("nextBtn").textContent = nextBtnText;
      document.getElementById("Submit").style.display = "none";
    } 
    //... and run a function that will display the correct step indicator:
    fixStepIndicator(n);
    fixPageStep(n)
  }

  /* runValidation function to check for any required fields, run validation and display correct error message */
  var runValidation = function(inp) {
    var liveRegion = getFollowingSibling(inp, 'span');
       
    if(inp.parentNode.querySelector(':invalid')) {
      if(inp.type === 'email' && inp.value) {
      displayError(inp, liveRegion, options.messageInvalidEmail);
      } 
      else if(inp.id === 'donation_postcode' && inp.value) {
        displayError(inp, liveRegion, options.messageInvalidPostcode);
      }
      else {
        let fieldName = liveRegion.id;
        fieldName = fieldName.substring(0, fieldName.lastIndexOf('-')).split('-').join(' ');
        let customError = inp.dataset.customerror;
        /* Display customError msg if available on field otherwise get fieldname and display as error msg */
        if (customError && customError.length > 0) {
          displayError(inp, liveRegion, customError + ' field is required'); 
        } else {
          displayError(inp, liveRegion, 'The ' + fieldName.split('_').join(' ') + ' field is required'); 
        }
      }
    }
  }

  /* Create error List messages for front end validation */
  let createErrorList = function(inp) {
    let liveRegion = getFollowingSibling(inp, 'span');
    if(inp.parentNode.querySelector(':invalid')) {
      if(inp.type === 'email' && inp.value) {
      addErrorListItem(options.messageInvalidEmail, inp.id);
      } 
      else if(inp.id === 'donation_postcode' && inp.value) {
        addErrorListItem(options.messageInvalidPostcode, inp.id);
      }
      else {
        let fieldID = inp.id;
        let fieldName = liveRegion.id;
        fieldName = fieldName.substring(0, fieldName.lastIndexOf('-')).split('-').join(' ');
        fieldName = capitalizeFirstLetter(fieldName);
        let customError = inp.dataset.customerror;
        let fieldset;

        /* Display customError msg if available on field otherwise get fieldname and display as error msg */
        if (inp.type == "radio" && inp.closest('.Fieldset')) {
          let fieldsetID = inp.closest('.Fieldset').id;
          addErrorListItem(fieldName + ' is required', fieldsetID);
         } else if (customError && customError.length > 0) {
          addErrorListItem(customError + ' is required', fieldID); 
        } else {
          addErrorListItem(fieldName + ' is required', fieldID); 
        }
      }
    }
  }

  function addErrorListItem(errorText, fieldID) {
    var li = document.createElement("li");
    li.classList.add("ErrorSummary-item");
    var a = document.createElement('a');
    var linkText = document.createTextNode(errorText);
    a.appendChild(linkText);
    a.classList.add("ErrorSummary-link");
    a.href = "#" + fieldID;
    li.appendChild(a);
    ul.appendChild(li);
  }
 
  document.getElementById("nextBtn").addEventListener("click", function() { 
  // Check if page has giftaid content and add visible classes to related input, to check validation
  let checkHiddenGiftaidContent = form.querySelectorAll('.formSection.active .Giftaid-content');
  let checkRequiredFieldsVisible = form.querySelectorAll('.formSection.active .Giftaid--show [required]:invalid');
  let donationTypeInvalid = form.querySelector('.formSection.active #donation_donation_type [required]:invalid');
  let frontendErrorList = document.querySelector('#FrontendValidation');
    // Clear any existing message - disable this for now until needed
   /**  var formAlert = document.querySelector(options.submitMessage);
    formAlert.textContent = ''; */

    // Check for invalid form fields
    var invalids = form.querySelectorAll('.formSection.active input:invalid');

    if(checkHiddenGiftaidContent.length) {
      if (donationTypeInvalid !== null) {
       ul.innerHTML = "";
       runValidation(donationTypeInvalid);
       createErrorList(donationTypeInvalid);
       frontendErrorList.style.display="block";
       frontendErrorList.scrollIntoView(true, { behavior: "smooth" });

      } else if (checkRequiredFieldsVisible.length > 0) {
        ul.innerHTML = "";
        checkRequiredFieldsVisible.forEach(elemInput => {
        runValidation(elemInput);
        createErrorList(elemInput);
        frontendErrorList.style.display="block";
        frontendErrorList.scrollIntoView(true, { behavior: "smooth" });
        }); 
      } else {
        ul.innerHTML = "";
        frontendErrorList.style.display="none";
        nextPrev(1);
    }

    } else if (invalids.length) {
        ul.innerHTML = "";
        for(var i = 0, ii = controls.length; i < ii; i++) {
        runValidation(controls[i]);
        createErrorList(controls[i]);
        frontendErrorList.style.display="block";
        frontendErrorList.scrollIntoView(true, { behavior: "smooth" });
      }
     
      const frontendValidationError = document.querySelector('[aria-describedby=address-line1-error]');
      if (frontendValidationError) {
        document.querySelector(".AddressLookup").style="display:block";
      } 
    } else {
    ul.innerHTML = "";
    frontendErrorList.style.display="none";
    nextPrev(1);
    }
  });

  document.getElementById("prevBtn").addEventListener("click", function() { 
    ul.innerHTML = "";
    document.querySelector('#FrontendValidation').style.display="none";
    nextPrev(-1);
  });

  function nextPrev(n) {
    // This function will figure out which tab to display
    var x = document.getElementsByClassName("formSection");

    // Hide the current tab:
    x[currentStep].style.display = "none";
    // Increase or decrease the current tab by 1:
    currentStep = currentStep + n;
    // if you have reached the end of the form...
    if (currentStep >= x.length) {
      // ... the form gets submitted:
      return;
    }
    // Otherwise, display the correct tab:
    showStep(currentStep);
  } 

  function fixStepIndicator(n) {
    // This function removes the "active" class of all steps...
    var i, x = document.getElementsByClassName("step");

    for (i = 0; i < x.length; i++) {
      x[i].classList.remove("active");
     
      if ( i < n) {
        x[i].classList.add("completed");
      }
    }
    //... and adds the "active" class on the current step:
    x[n].classList.add("active");
  }

  form.addEventListener('submit', function(e) {
   // var invalidPhoneField = form.querySelector('.phoneField input:invalid');
    let invalidPhoneField = form.querySelectorAll('.formSection.active .phoneField--show input:invalid');
    let frontendErrorList = document.querySelector('#FrontendValidation');
      if(invalidPhoneField.length) {
        e.preventDefault();
        ul.innerHTML = "";
        invalidPhoneField.forEach(invalidElement => {
        runValidation(invalidElement);
        createErrorList(invalidElement);
        frontendErrorList.style.display="block";
        frontendErrorList.scrollIntoView(true, { behavior: "smooth" });
        });
      }
    });
};

function fixPageStep(n) {
   // This function removes the "active" class of all steps...
   var i, y = document.getElementsByClassName("formSection");

   for (i = 0; i < y.length; i++) {
     y[i].classList.remove("active");
   }
   //... and adds the "active" class on the current step:
   y[n].classList.add("active");
}
