const manualAddressButton = document.querySelector(".manualAddress");
  if (manualAddressButton) {
    manualAddressButton.addEventListener("click", function(event){
    event.preventDefault();
    document.querySelector(".AddressLookup").style="display:block";
  });
}

const addressLookup = document.querySelector(".AddressLookup");

if (addressLookup) {
let address = { 
  searchAddress : document.querySelector('#address_search'),
  addressLine1 : document.querySelector('#donation_address_line_1'), 
  addressLine2 : document.querySelector('#donation_address_line_2'), 
  town : document.querySelector('#donation_town'), 
  postcode: document.querySelector('#donation_postcode'), 
  country : document.querySelector('#donation_mailing_country')
}; 

window.onload = function() { 
  var config = { defaultCountry: 'gbr', countryLanguage: 'en', accessToken: '6d5b7-08304-5eb5c-0838a', domMode: 'object', getIpLocation: false, placeholders: true,
    texts: {
      default_placeholder: 'Start typing your address',
    }
  }

cc = new clickToAddress(config);

cc.attach({
    search:     address.searchAddress, // 'search_field' is the name of the search box element
    line_1:     address.addressLine1,
    line_2:     address.addressLine2,
    town:       address.town,
    postcode:   address.postcode,
    country:    address.country
  },
  {
    onResultSelected: function(c2a, elements, address) {
      document.querySelector(".AddressLookup").style="display:block";
      document.querySelector(".manualAddress").style="display:none";
    },
  
});
}
}

const addressErrored = document.querySelector('.Field--errored');
const fieldsetErrored = document.querySelector('.Fieldset--errored');
const address1Filled = document.querySelector('#donation_address_line_1');
const frontendValidationError = document.querySelector('[aria-describedby=address-line1-error]');

if (addressErrored || fieldsetErrored || frontendValidationError) {
  document.querySelector(".AddressLookup").style="display:block";
}

if (address1Filled && address1Filled.value.length > 0) {
  document.querySelector(".AddressLookup").style="display:block";
}
